<template>
        <div class="col-md-6 col-12 d-flex flex-wrap align-items-center justify-content-center realizacao">
            <loading-ajax v-if="loading"></loading-ajax>
            <div v-else class="logo-realizacao" v-for="(item) in researchCenters" :key="item.id">
                <a :href="item.urlSite" target="_blank">
                    <img :src="item.urlImage" alt="" :width="item.width" :height="item.height">
                </a>
            </div>
        </div>
</template>

<script>
import api from '@/services/api.js'


export default {
    components: {
    },
    data() {
        return {
            researchCenters: [],
            loading: true

        }
    },
    async created() {

        var t = this
        await api.get('/researchCenters').then(response => (
            t.researchCenters = response.data.data
        )).finally(()=>{this.loading=false})
    },
    methods: {

    }
}
</script>

<style>
    .realizacao {
        margin: 1.5rem;
    }
    .realizacao img {
        transition: transform .1s;
        width: 75%
    }
    .realizacao img:hover {
        transform: scale(1.2)
    }
    .logo-realizacao {
        width: 12%;
        margin-top:2rem;
    }
    @media only screen and (max-width: 575px) {
        .logo-realizacao {
            width: 25%;
        }
    }
</style>